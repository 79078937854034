import { ComponentStyleConfig } from '@chakra-ui/react';

export const Button: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: 700,
    backgroundColor: `rgba(0, 249, 255, 1)`,
    borderRadius: '48px',
    lineHeight: { base: `20px`, md: `22px` },
    color: `var(--chakra-colors-custom-text-gradient)`,
    border: `none`,
    _hover: { outline: `none`, border: `none` },
    _focus: { outline: `none`, border: `none` },
    _active: {
      background: `var(--chakra-colors-whiteAlpha-400)`,
    },
    height: { base: `48px`, md: `60px` },
  },
  variants: {
    bannerButton: {
      padding: { base: `14px 32px 14px 32px`, md: `18px 32px 18px 32px` },
      fontSize: { base: `16px`, md: `18px` },
    },
    sectionButton: {
      padding: { base: `14px 32px 14px 32px`, md: `18px 60px 18px 60px` },
      fontSize: { base: `16px`, md: `18px` },
    },
    formButton: {
      fontSize: { base: `16px`, md: `18px` },
      padding: { base: `14px 32px 14px 32px`, md: `18px 32px 18px 32px` },
      lineHeight: { base: `20px`, md: `22px` },
    },
  },
};
