import React from 'react';
import { Link } from 'react-scroll';
import { motion } from 'framer-motion';
import { StyledContainer, StyledFlexBox } from '@hive-microsite-ui/components/Atoms';
import { HiveText } from '@hive-microsite-ui/components';
import { Button, Text } from '@chakra-ui/react';

const AnimatedDivContainer = motion(StyledFlexBox);

export const AnimatedBannerContent: React.FC = () => {
  return (
    <AnimatedDivContainer
      mt={{
        base: 10,
        lg: 4,
      }}
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.7, delay: 1 }}
      justifyContent={`center`}
      alignItems={`center`}
      flexDirection={`column`}
    >
      <HiveText
        TextProps={{
          mt: 4,
          maxWidth: { base: '264px', md: '582px' },
          textStyle: 'banner.description',
        }}
        text="HIVE is transforming the way insurance products are designed, sold, and utilised. Use our innovative solutions
        and network of partners to thrive in today's competitive market."
      />

      <StyledContainer mt="42px">
        <Link to="section__contact-us" spy={true} smooth={true} offset={-160}>
          <Button variant={`bannerButton`}>
            <Text>Connect with us</Text>
          </Button>
        </Link>
      </StyledContainer>
    </AnimatedDivContainer>
  );
};
