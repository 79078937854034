import React, { PropsWithChildren } from 'react';
import { StyledContainer } from '@hive-microsite-ui/components/Atoms';
import { AnimatedBannerHeading } from './AnimatedBannerHeading';
import { AnimatedBannerContent } from './AnimatedBannerContent';

export const AnimatedBannerInView: React.FC<PropsWithChildren> = React.memo(() => {
  return (
    <StyledContainer>
      <AnimatedBannerHeading />
      <AnimatedBannerContent />
    </StyledContainer>
  );
});
