import React, { CSSProperties, PropsWithChildren, ReactElement } from 'react';
import { useBreakpointValue } from '@chakra-ui/react';
import { useInView } from 'react-intersection-observer';

import { StyledContainer } from '../Atoms';
import { Heading, SubHeading } from '../Atoms/Typography';

import AnimatedSection from './AnimatedSection';
import { AnimatedSectionHeader } from './AnimatedSectionHeader';
import { AnimatedSectionContent } from './AnimatedSectionContent';
import { AnimatedDivider, PageSectionDividerProps } from './AnimatedDivider';

import { StyledSectionWrapper, StyledSectionContainer } from './styled';

export type BgWave = undefined | `section_why_hive` | `section_our_solutions`;

export interface ComponentProps {
  sectionId: string;
  element?: ReactElement;
  bgWave?: BgWave;
  sectionDivierProps: Omit<PageSectionDividerProps, 'inView'>;
  title?: string | ReactElement;
  description?: string | ReactElement;
  sectinoElement: ReactElement;
  flexDirection: CSSProperties[`flexDirection`];
}

export const SectionWrapper: React.FC<PropsWithChildren & { bgWave: BgWave }> = ({ children, bgWave }) => {
  return (
    <StyledSectionWrapper
      pos={`relative`}
      bgWave={bgWave}
      display={`flex`}
      flexDirection="column"
      pl={{
        base: 6,
        md: 8,
        lg: 14,
        xl: 16,
      }}
      pr={{
        base: 6,
        md: 8,
        lg: 14,
        xl: 16,
      }}
      w={`full`}
      minH={`60vh`}
      justifyContent={`center`}
      alignItems={`center`}
    >
      {children}
    </StyledSectionWrapper>
  );
};

export const PageSection: React.FC<ComponentProps> = ({
  sectionId: id,
  title,
  description,
  sectinoElement: SectionContent,
  sectionDivierProps,
  bgWave,
  flexDirection,
}) => {
  const isMobileOnly = useBreakpointValue({ base: true, md: false });

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const requiredHeaderSection = title || description;
  const textAlign = !isMobileOnly && flexDirection == `row` ? `left` : `center`;
  return (
    <AnimatedSection sectionId={id}>
      <StyledContainer pos={`relative`} ref={ref}>
        <AnimatedDivider {...sectionDivierProps} inView={inView} />
        <SectionWrapper bgWave={bgWave}>
          <StyledSectionContainer
            display={`flex`}
            flexDirection={isMobileOnly ? `column` : flexDirection}
            pt={{
              base: 10,
              md: 10,
              lg: 12,
            }}
            pb={{
              base: 10,
              md: 10,
              lg: 12,
            }}
            gap={{
              base: 10,
              md: 14,
              lg: 14,
            }}
          >
            {requiredHeaderSection && (
              <AnimatedSectionHeader
                flex={{
                  base: 1,
                  md: 1.25,
                  lg: 1.25,
                }}
                alignItems={textAlign}
                inView={inView}
              >
                {title && <Heading textAlign={textAlign}>{title}</Heading>}
                {description && <SubHeading textAlign={textAlign}>{description}</SubHeading>}
              </AnimatedSectionHeader>
            )}
            <AnimatedSectionContent flex={1} inView={inView}>
              {SectionContent}
            </AnimatedSectionContent>
          </StyledSectionContainer>
        </SectionWrapper>
      </StyledContainer>
    </AnimatedSection>
  );
};
