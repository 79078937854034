import React from 'react';
import { motion } from 'framer-motion';
import { BoxProps } from '@chakra-ui/react';
import { StyledFlexBox } from '../Atoms';

type ComponentProps = BoxProps & {
  inView: boolean;
};

const AnimatedFlexBox = motion(StyledFlexBox);

export const AnimatedSectionHeader: React.FC<ComponentProps> = ({ children, inView, ...props }) => {
  return (
    <AnimatedFlexBox
      as={motion.div}
      gap={{
        base: 4,
        md: 8,
        lg: 8,
      }}
      flexDirection={`column`}
      textAlign={`center`}
      initial={{ opacity: 0, y: -90, scale: 0.8 }}
      animate={inView ? { opacity: 1, y: 0, scale: 1 } : { opacity: 0, y: -90, scale: 0.8 }}
      transition={{
        duration: 0.5,
        ease: `easeIn`,
      }}
      {...props}
    >
      {children}
    </AnimatedFlexBox>
  );
};
