import { createBrowserRouter } from 'react-router-dom';
import { RootLayout } from '../layouts/RootLayout';

import { LandingPage } from '../pages/landingPage';

export const router = createBrowserRouter(
  [
    {
      element: <RootLayout />,
      children: [
        {
          path: '/',
          element: <LandingPage />,
        },
      ],
    },
  ],
  {
    basename: import.meta.env.VITE_HIVE_WEB_BASE_URL,
  },
);
