import { Box, Heading, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';

type EmbedInsuranceItemProps = {
  opacity: number;
  isActive: boolean;
  heading: string;
  text: string;
  positionY: number;
  height: string;
};

export const EmbedInsuranceItem: React.FC<EmbedInsuranceItemProps> = ({
  isActive = false,
  heading = '',
  text = '',
  positionY = 0,
  height,
  opacity,
}) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="flex-start"
      flexDirection="column"
      textAlign={`left`}
      as={motion.div}
      cursor="pointer"
      opacity={opacity}
      animate={{ y: positionY, transition: { type: 'spring', duration: 0.75 } }}
      height={height}
      lineHeight="28px"
    >
      <Heading fontSize={isActive ? '34px' : '22px'}>{heading}</Heading>
      <Text>{text}</Text>
    </Box>
  );
};
