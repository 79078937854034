import { Text } from '@chakra-ui/react';
import { Link } from 'react-scroll';
import { StyledFlexBox } from '../Atoms';
import { NavigationConstants } from '@hive-microsite-ui/constants';

export const FooterMenu: React.FC = () => {
  return (
    <StyledFlexBox flexDirection={`row`} display={`flex`} gap={{ base: 0, md: 2, lg: 2 }} alignItems={`center`}>
      {...NavigationConstants.map((item, index) => (
        <Link
          key={index}
          to={`${item.goToSection}`}
          title={item.title}
          spy={true}
          smooth={true}
          offset={-180}
          duration={500}
          style={{
            color: `white`,
          }}
          onClick={() => {
            if (item.href) {
              window.location.href = item.href;
            }
          }}
        >
          <Text
            as={`span`}
            px={{ base: `6px`, lg: `12px`, xl: `16px` }}
            cursor={`pointer`}
            _hover={{
              color: `white`,
            }}
          >
            {item.title}
          </Text>
        </Link>
      ))}
    </StyledFlexBox>
  );
};
