import styled from '@emotion/styled';
import { StyledContainer } from '../Atoms';
import { css } from '@emotion/react';

import imgWhyHiveSectionBgWave from '@hive-microsite-ui/assets/imgs/img_why-hive-bg-wave.svg';
import imgInnovateInsuranceSectionBgWave from '@hive-microsite-ui/assets/imgs/img_innovative-insurance-bg-wave.svg';

export type BgWave = undefined | `section_why_hive` | `section_our_solutions`;

export const StyledSectionContainer = styled(StyledContainer)`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const StyledSectionWrapper = styled(StyledContainer)<{ bgWave?: BgWave }>`
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  ${({ bgWave }) =>
    bgWave &&
    css`
      background-size: 100vw !important;
      background: url(${bgWave === 'section_our_solutions'
          ? imgInnovateInsuranceSectionBgWave
          : imgWhyHiveSectionBgWave})
        no-repeat;
    `};
`;
