import React, { useMemo } from 'react';
import Lottie from 'react-lottie';

interface ComponentProps {
  width: number | string | undefined;
  height?: number;
  animationData: unknown;
  loop?: boolean;
}

export const LottieAnim: React.FC<ComponentProps> = ({ animationData, width, loop }) => {
  const options = useMemo(() => {
    const defaultOptions = {
      loop: !!loop,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
    return defaultOptions;
  }, [animationData, loop]);

  return <Lottie options={options} speed={0.5} width={width} />;
};
