import { Drawer, DrawerOverlay, DrawerProps } from '@chakra-ui/react';
import { StyledContainer } from '../Atoms';
import { MenuToggleButton } from './ToggleButton';
import { StyledDrawerContent, StyledDrawerBg, StyledDrawerBody } from './styled';
import { useCallback } from 'react';

type ComponentProps = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  renderDrawerContent: () => React.ReactNode;
  drawerProps?: Omit<DrawerProps, 'isOpen' | 'onClose' | 'children'>;
};

export const HiveDrawer: React.FC<ComponentProps> = (props) => {
  const { isOpen, onOpen, onClose, renderDrawerContent, drawerProps } = props;

  const toggleDrawerMenu = useCallback(() => {
    isOpen ? onClose() : onOpen();
  }, [isOpen, onOpen, onClose]);

  return (
    <StyledContainer>
      <MenuToggleButton isOpen={isOpen} onClick={toggleDrawerMenu} />
      <Drawer isOpen={isOpen} size="full" onClose={onClose} {...drawerProps}>
        <DrawerOverlay />
        <StyledDrawerContent bg={`bg`}>
          <StyledDrawerBody w={`full`} mt={24} p={6} pos={`relative`}>
            {renderDrawerContent()}
          </StyledDrawerBody>
          <StyledDrawerBg />
        </StyledDrawerContent>
      </Drawer>
    </StyledContainer>
  );
};
