import { Box, DrawerBody, DrawerContent } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import imgDrawerBackgroundWave from '@hive-microsite-ui/assets/imgs/img_bg-wave.svg';
import imgDrawerBackground from '@hive-microsite-ui/assets/imgs/img_drawer-bg.svg';
import { StyledContainer } from '../Atoms';

interface HamburgerMenuBarProps {
  isOpen: boolean;
  barPosition: `top` | `middle` | `bottom`;
}

export const StyledHamburgerMenuBar = styled(Box)<HamburgerMenuBarProps>`
  width: 18px;
  height: 2.5px;
  background-color: #00f9ff;
  margin: 2px 0;
  transition: 0.4s;
  ${({ isOpen, barPosition }) => {
    if (isOpen && barPosition === `top`) {
      return css`
        transform: translate(0, 5px) rotate(-45deg);
      `;
    }
    if (isOpen && barPosition === `middle`) {
      return css`
        opacity: 0;
      `;
    }
    if (isOpen && barPosition === `bottom`) {
      return css`
        transform: translate(0, -8px) rotate(45deg);
      `;
    }
  }};
`;

export const StyledMenuToggleButton = styled(Box)`
  width: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledDrawerContent = styled(DrawerContent)`
  padding: 0;
  z-index: 9;
  position: relative;
  /* background-color: linear-gradient(0deg, rgba(0, 40, 53, 1) 0%, rgba(0, 40, 53, 1) 40%, rgba(0, 40, 53, 1) 100%); */
  background: #02232e url(${imgDrawerBackgroundWave}) center 44px no-repeat;
  background-size: contain;
`;

export const StyledDrawerBody = styled(DrawerBody)`
  left: 0;
  top: 0;
  position: absolute;
  display: flex;
  justify-content: stretch;
  height: 100%;
  z-index: 999;
`;

export const StyledDrawerBg = styled(StyledContainer)`
  position: absolute;
  bottom: 48px;
  right: 0;
  width: 328px;
  height: 328px;
  background: url(${imgDrawerBackground}) 0px 0px no-repeat;
`;
