import React from 'react';
import { Text } from '@chakra-ui/react';

interface ComponentProps {
  emphasisWord: string;
  content: string;
}

export const BannerHighlightText: React.FC<ComponentProps> = ({ emphasisWord, content }) => {
  return (
    <Text as={`h1`} textStyle={'banner.heading'}>
      <Text as="strong" fontWeight={900}>
        {emphasisWord}
      </Text>
      <Text as={`span`}>{content}</Text>
    </Text>
  );
};
