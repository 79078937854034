import React from 'react';

import { StyledHamburgerMenuBar, StyledMenuToggleButton } from './styled';

interface ComponentProps {
  isOpen: boolean;
  onClick: () => void;
}

export const MenuToggleButton: React.FC<ComponentProps> = ({ isOpen, onClick }) => {
  return (
    <StyledMenuToggleButton onClick={onClick}>
      <StyledHamburgerMenuBar barPosition={'top'} isOpen={isOpen} />
      <StyledHamburgerMenuBar barPosition={'middle'} isOpen={isOpen} />
      <StyledHamburgerMenuBar barPosition={'bottom'} isOpen={isOpen} />
    </StyledMenuToggleButton>
  );
};
