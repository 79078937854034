import React, { PropsWithChildren } from 'react';
import { motion } from 'framer-motion';
import { BoxProps } from '@chakra-ui/react';
import { StyledFlexBox } from '../Atoms';

type ComponentProps = PropsWithChildren &
  BoxProps & {
    inView: boolean;
  };

const AnimatedFlexBox = motion(StyledFlexBox);

export const AnimatedSectionContent: React.FC<ComponentProps> = ({ children, inView, ...props }) => {
  return (
    <AnimatedFlexBox
      as={motion.div}
      w={`full`}
      flexDirection={`column`}
      alignItems={`center`}
      gap={8}
      textAlign={`center`}
      // border={`1px dotted green`}
      initial={{ opacity: 0, y: 90 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 90 }}
      transition={{
        duration: 0.5,
        ease: `easeIn`,
      }}
      {...props}
    >
      {children}
    </AnimatedFlexBox>
  );
};
