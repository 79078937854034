import React from 'react';
import { BrandLogo } from './BrandLogo';
import { NavigationMenu } from '../NavigationMenu';
import { StyledHeaderContainer, StyledNavBarContainer } from './styled';

export const Header: React.FC = () => {
  return (
    <StyledHeaderContainer
      padding={{
        base: `24px`,
        md: `48px`,
      }}
    >
      <BrandLogo />
      <StyledNavBarContainer>
        <NavigationMenu />
      </StyledNavBarContainer>
    </StyledHeaderContainer>
  );
};
