import React from 'react';
import { Image } from '@chakra-ui/react';
import { Link } from 'react-scroll';

import imgHiveBrandLogo from '@hive-microsite-ui/assets/imgs/img_hive-brand-logo.svg';

export const BrandLogo: React.FC = () => {
  return (
    <Link to="section_hero" spy={true} smooth={true} offset={-160}>
      <Image
        width={{
          base: `72px`,
          lg: `104px`,
        }}
        height={{
          base: `28px`,
          lg: `40px`,
        }}
        src={imgHiveBrandLogo}
        alt="HIVE By INCOME"
        cursor={'pointer'}
      />
    </Link>
  );
};
