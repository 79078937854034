import React from 'react';
import { motion } from 'framer-motion';
import { BannerHighlightText } from './BannerHighlightText';
import { StyledContainer, StyledFlexBox } from '@hive-microsite-ui/components/Atoms';

const AnimatedDivContainer = motion(StyledContainer);

export const AnimatedBannerHeading: React.FC = React.memo(() => {
  return (
    <AnimatedDivContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, delay: 1, ease: 'easeInOut' }}
      pl={{
        base: 8,
        lg: 32,
      }}
    >
      <StyledFlexBox>
        <BannerHighlightText emphasisWord="IN" content="SURANCE." />
      </StyledFlexBox>
      <StyledFlexBox>
        <BannerHighlightText emphasisWord="RE" content="-IMAGINED." />
      </StyledFlexBox>
    </AnimatedDivContainer>
  );
});
