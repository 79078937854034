import React from 'react';
import { Box, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { EmbedInsuranceType } from '@hive-microsite-ui/constants';

interface ComponentProps {
  data: EmbedInsuranceType[];
  currentIndex: number;
  onClick: (index: number) => void;
}

export const EmbededInsuranceList: React.FC<ComponentProps> = ({ data, currentIndex, onClick }) => {
  return (
    <UnorderedList spacing="8px">
      {(data ?? []).map((item, index) => (
        <ListItem
          key={index}
          color={index === currentIndex ? 'white' : 'primary.100'}
          cursor="pointer"
          fontWeight="bold"
          textAlign={`left`}
          display={`flex`}
          alignItems={`center`}
          transition={`all 0.2s ease-in`}
          gap={4}
          mb={4}
          onClick={() => onClick(index)}
          listStyleType={index === currentIndex ? 'none' : 'none'}
        >
          <Box
            as="span"
            w={2}
            height={2}
            borderRadius={`10px`}
            display={`inline-block`}
            transition={`all 0.2s ease-in`}
            bg={index === currentIndex ? `primary.100` : `transparent`}
          />
          <Text transition={`all 0.2s ease-in`}>{item.title}</Text>
        </ListItem>
      ))}
    </UnorderedList>
  );
};
