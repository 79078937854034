import React from 'react';
import styled from '@emotion/styled';
import { Image } from '@chakra-ui/react';

import { LottieAnim } from '@hive-microsite-ui/components/LottieAnim';
import { StyledContainer } from '@hive-microsite-ui/components/Atoms';
import { BackgroundVideoPlayer } from './BackgroundVideoPlayer';
import { AnimatedBannerInView } from './AnimatedBannerInView';

import hiveBannerLottieAnimJsonData from '@hive-microsite-ui/assets/json/lottie_hive-banner-bubble.json';

const StyledBannerContainer = styled(StyledContainer)`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`;

export const StyledBannerBg = styled(Image)`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 80%;
  object-fit: cover;
`;

const StyledBannerContent = styled(StyledContainer)`
  position: absolute;
`;

const StyledLottieAnim = styled(StyledContainer)`
  position: absolute;
`;

export const Banner: React.FC = React.memo(() => {
  return (
    <StyledBannerContainer
      w={`full`}
      pos={`relative`}
      minH={{
        base: `50vh`,
        md: `600px`,
        lg: `85vh`,
      }}
      pt={8}
      id="section_hero"
    >
      <StyledContainer w={`full`} height={`full`} pos={`absolute`} top={0} pt={8}>
        <BackgroundVideoPlayer />
        <StyledLottieAnim>
          <LottieAnim animationData={hiveBannerLottieAnimJsonData} width={`100vw`} height={400} />
        </StyledLottieAnim>
      </StyledContainer>
      <StyledBannerContent
        top={{
          base: `32px`,
          sm: `32px`,
          md: `48px`,
          lg: `48px`,
        }}
      >
        <AnimatedBannerInView />
      </StyledBannerContent>
    </StyledBannerContainer>
  );
});
