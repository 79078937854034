import z, { ZodError } from 'zod';
import { initFormState } from './reducer';

export enum FormStatusEnum {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN-PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

const emptyError = 'Please complete this field.';

// Zod schema for form validation
const FormDataSchema = z.object({
  name: z.string().min(1, emptyError),
  companyName: z.string().min(1, emptyError),
  role: z.string().min(1, emptyError),
  companyEmail: z.string().email('Please fill a valid email').min(1, emptyError),
  companyNumber: z.string().regex(/^\d+$/, { message: 'Please fill a valid contact number' }).min(1, emptyError),
});

// Zod schema for form validation
const FormErrorsSchema = z.object({
  name: z.string().default(''),
  companyName: z.string().default(''),
  role: z.string().default(''),
  companyEmail: z.string().default(''),
  companyNumber: z.string().default(''),
});

export const FormSchema = z.object({
  status: z.nativeEnum(FormStatusEnum).default(FormStatusEnum.PENDING),
  isSubmitted: z.boolean().default(false),
  data: FormDataSchema,
  errors: FormErrorsSchema,
});

export type FormStatus =
  | FormStatusEnum.PENDING
  | FormStatusEnum.IN_PROGRESS
  | FormStatusEnum.COMPLETED
  | FormStatusEnum.FAILED;
export type FormState = z.infer<typeof FormSchema>;

// Validation function
export const validateFormData = (formData: FormState['data']): { [key: string]: string } => {
  try {
    FormDataSchema.parse(formData);
    return initFormState.errors;
  } catch (error) {
    if (error instanceof ZodError) {
      return error.errors.reduce<{ [key: string]: string }>(
        (errors, validationError) => {
          const { path, message } = validationError;

          if (path && path.length > 0) {
            const fieldName = path[0];
            return { ...errors, [`${fieldName}`]: message };
          }
          return errors;
        },
        { ...initFormState.errors },
      );
    }
    return initFormState.errors;
  }
};
