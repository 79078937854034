import { ActionType, FormAction } from './actions';
import { FormState, FormStatusEnum } from './validation-form';

export const initFormState = {
  status: FormStatusEnum?.PENDING ?? 'PENDING',
  isSubmitted: false,
  data: {
    name: '',
    companyName: '',
    role: '',
    companyEmail: '',
    companyNumber: '',
  },
  errors: {
    name: '',
    companyName: '',
    role: '',
    companyEmail: '',
    companyNumber: '',
  },
};

// Define reducer function
export const reducer = (state: FormState, action: FormAction): FormState => {
  switch (action.type) {
    case ActionType.CHANGE_INPUT_FIELD:
      return {
        ...state,
        isSubmitted: false,
        data: {
          ...state.data,
          [action.fieldName]: action.fieldValue,
        },
      };
    case ActionType.SUBMIT_FORM: {
      return {
        ...state,
        isSubmitted: true,
      };
    }
    case ActionType.RESET_FORM:
      return {
        ...state,
        isSubmitted: false,
        status: FormStatusEnum.PENDING,
        data: {
          ...state.data,
          ...initFormState.data,
        },
      };
    case ActionType.SET_FORM_ERRORS:
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload,
        },
      };
    case ActionType.CHANGE_FORM_STATUS:
      return {
        ...state,
        status: action.status,
      };
    default:
      return state;
  }
};
