import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { router } from './router/router.tsx';

import './styles/common/index.css';

if (process.env.NODE_ENV === 'development') {
  const { worker } = await import('@hive-microsite-ui/mocks/brower');
  await worker.start();
}

// eslint-disable-next-line no-type-assertion/no-type-assertion
ReactDOM.createRoot(document.querySelector(`#root`)!).render(<RouterProvider router={router} />);
