import { Grid } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { StyledContainer } from '../Atoms';

export const ResponsiveGrid: React.FC<{ children: ReactElement | ReactElement[]; noOfItems: number }> = ({
  children,
  noOfItems,
}) => {
  return (
    <StyledContainer w={`full`}>
      <Grid
        justifyContent={`space-around`}
        alignContent={`center`}
        gap={4}
        templateColumns={{
          base: `1fr`,
          md: `repeat(${noOfItems}, 1fr)`,
          lg: `repeat(${noOfItems}, 1fr)`,
        }}
      >
        {children}
      </Grid>
    </StyledContainer>
  );
};
