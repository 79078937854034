import React, { useEffect, useRef, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';

import { Heading, SubHeading, SubTitle } from '@hive-microsite-ui/components';

import './style.css';
import { EmbedInsuranceType } from '@hive-microsite-ui/constants';
import { EmbededInsuranceList } from './EmbededInsuranceList';
import { StyledContainer, StyledFlexBox } from '@hive-microsite-ui/components/Atoms';

export const EmbedInsuranceMobile: React.FC<{
  embedInsurances: EmbedInsuranceType[];
}> = ({ embedInsurances }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const prevIndexRef = useRef(currentIndex);

  useEffect(() => {
    prevIndexRef.current = currentIndex;
  }, [currentIndex]);

  const scrollToIndex = (index: number) => {
    setCurrentIndex(index);
  };

  const determineDirection = (current: number, previous: number) => {
    return current > previous ? 'right' : 'left';
  };

  const direction = determineDirection(currentIndex, prevIndexRef.current);

  return (
    <StyledFlexBox display="flex" justifyContent="center" flexDirection="column" gap="10px" mt="32px">
      <StyledFlexBox flexDirection="column" gap="20px">
        <Heading textAlign={{ base: 'center', md: 'left', lg: 'left' }}>Embed Insurance.</Heading>
        <SubHeading>
          HIVE enables companies from any sector to embed insurance into core products and services.
        </SubHeading>
        <EmbededInsuranceList
          data={embedInsurances}
          currentIndex={currentIndex}
          onClick={(index) => {
            scrollToIndex(index);
          }}
        />
      </StyledFlexBox>

      <StyledContainer
        position="relative"
        width="100%"
        maxWidth="348px"
        height="180px"
        overflow="hidden"
        mt={8}
        _before={{
          content: '""',
          position: 'absolute',
          inset: 0,
          borderRadius: '64px',
          padding: '8px',
          background: 'linear-gradient(180deg, #FFE68B 0%, #FA669C 26.95%, #84ACF3 59.24%, #A6F3FF 100%)',
          WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
          WebkitMaskComposite: 'xor',
          maskComposite: 'exclude',
        }}
      >
        <AnimatePresence>
          {embedInsurances.map(
            (item, index) =>
              index === currentIndex && (
                <motion.div
                  key={index}
                  style={{
                    position: 'absolute',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                  initial={{ x: direction === 'right' ? '100%' : '-100%' }}
                  animate={{ x: 0 }}
                  transition={{ type: 'spring', duration: 0.75 }}
                >
                  <Flex
                    key={index}
                    justifyContent={'center'}
                    alignItems={'flex-start'}
                    direction="column"
                    textAlign={`left`}
                    shrink={0}
                    maxW={'80%'}
                  >
                    <SubTitle>{item.title}</SubTitle>
                    <Text>{item.description}</Text>
                  </Flex>
                </motion.div>
              ),
          )}
        </AnimatePresence>
      </StyledContainer>
    </StyledFlexBox>
  );
};
