import { ReactElement } from 'react';
import { useBreakpointValue } from '@chakra-ui/react';

import { StyledContainer, StyledFlexBox } from '@hive-microsite-ui/components/Atoms';
import { Header } from '@hive-microsite-ui/components/Header';
import { Footer } from '@hive-microsite-ui/components/Footer';

export const ResponsivePageLayout: React.FC<{ children: ReactElement }> = ({ children }) => {
  // Use the useBreakpointValue hook to get the current breakpoint
  const isMobileOnly = useBreakpointValue({ base: true, md: false });

  console.debug(`Media query is  mobile only ? ${isMobileOnly}`);

  return (
    <StyledContainer bg="custom.background">
      <Header />
      <StyledFlexBox>{children}</StyledFlexBox>
      <Footer />
    </StyledContainer>
  );
};
