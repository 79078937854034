import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { StyledContainer } from '@hive-microsite-ui/components/Atoms';

const StyledBgContainer = styled(StyledContainer)`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 80%;
  object-fit: cover;
`;

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  object-fit: cover;
`;

export const BackgroundVideoPlayer: React.FC = () => {
  const videoPlayerRef = useRef<null | HTMLVideoElement>(null);

  return (
    <StyledBgContainer onContextMenu={(event) => event.preventDefault()}>
      <StyledVideo
        ref={videoPlayerRef}
        autoPlay
        loop
        muted
        playsInline
        disablePictureInPicture
        controlsList="nodownload"
      >
        <source src={`${import.meta.env.VITE_HIVE_WEB_BASE_URL}/video_hive-website-bg-new.mp4`} type="video/mp4" />
        Your browser does not support the video tag.
      </StyledVideo>
    </StyledBgContainer>
  );
};
