import React, { lazy } from 'react';

import { StyledFlexBox } from '@hive-microsite-ui/components/Atoms';
import { PageSection } from '@hive-microsite-ui/components/Section';

import { Banner } from './components/Banner';
import EmbedInsurance from './components/EmbedInsurance';

const Testimonials = lazy(async () => await import('./components/Testimonials'));
const Awards = lazy(async () => await import('./components/Awards'));
const GoToMarket = lazy(async () => await import('./components/GoToMarket'));
const ContactUs = lazy(async () => await import('./components/ContactUs'));

import { InnovateInsuranceStaticData, WhyHiveStaticData } from '@hive-microsite-ui/constants';
import { ResponsiveCardWithCarousel } from '@hive-microsite-ui/components/ResponsiveCardWithCarousel';

export const LandingPage: React.FC = () => {
  return (
    <StyledFlexBox flexDirection="column" mt={{ base: `86px`, lg: `96px` }} w={`full`}>
      <Banner />
      <PageSection
        sectionId="section__why-hive"
        bgWave={`section_why_hive`}
        flexDirection="column"
        sectionDivierProps={{
          maxWidth: `40%`,
          dotIndicatorType: `blue`,
          direction: `left`,
        }}
        title={
          <>
            Leading Incumbent Insurers
            <br />
            Into A Brave New World
          </>
        }
        description="Build new insurance products within your existing systems via HIVE's insurance-as-a-service platform."
        sectinoElement={<ResponsiveCardWithCarousel sectionData={WhyHiveStaticData} />}
      />
      <PageSection
        sectionId="section__our-solutions"
        flexDirection="column"
        bgWave={`section_our_solutions`}
        sectionDivierProps={{
          maxWidth: `40%`,
          dotIndicatorType: `pink`,
          direction: `right`,
        }}
        title={`Innovate Insurance.`}
        description="Develop new business models and value propositions with HIVE's digital solutions."
        sectinoElement={<ResponsiveCardWithCarousel sectionData={InnovateInsuranceStaticData} />}
      />
      <PageSection
        sectionId="section__embed-insurance"
        flexDirection="column"
        sectionDivierProps={{
          maxWidth: `40%`,
          dotIndicatorType: `blue`,
          direction: `left`,
        }}
        sectinoElement={<EmbedInsurance />}
      />
      <PageSection
        sectionId="section__go-to-market"
        flexDirection="column"
        sectionDivierProps={{
          maxWidth: `30%`,
          dotIndicatorType: `pink`,
          direction: `right`,
        }}
        title="Go-to-market"
        description="In as little as 3 months, with our expertise in the insurance business."
        sectinoElement={<GoToMarket />}
      />
      <PageSection
        sectionId="section__testiminials"
        flexDirection="column"
        sectionDivierProps={{
          maxWidth: `40%`,
          dotIndicatorType: `blue`,
          direction: `left`,
        }}
        title="What our customers say:"
        sectinoElement={<Testimonials />}
      />
      <PageSection
        sectionId="section__awards"
        flexDirection="column"
        sectionDivierProps={{
          maxWidth: `40%`,
          dotIndicatorType: `pink`,
          direction: `right`,
        }}
        title="Awards"
        sectinoElement={<Awards />}
      />
      <PageSection
        sectionId="section__contact-us"
        flexDirection="row"
        sectionDivierProps={{
          maxWidth: `40%`,
          dotIndicatorType: `blue`,
          direction: `left`,
        }}
        title="Be a trailblazer insurer today!"
        description="Experience the power of our solutions and redefine insurance business in the digital age"
        sectinoElement={<ContactUs />}
      />
    </StyledFlexBox>
  );
};
