import icAboutUsForward from '@hive-microsite-ui/assets/icons/ic_about-us-forward.svg';
import icAboutUsRunning from '@hive-microsite-ui/assets/icons/ic_about-us-running.svg';
import icAboutUsRoute from '@hive-microsite-ui/assets/icons/ic_about-us-route.svg';

import icAboutUsStackable from '@hive-microsite-ui/assets/icons/ic_about-us-stackable.svg';
import icAboutUsSubscription from '@hive-microsite-ui/assets/icons/ic_about-us-subscription.svg';
import icAboutUsParametric from '@hive-microsite-ui/assets/icons/ic_about-us-parametric.svg';
import icAboutUsUsageBased from '@hive-microsite-ui/assets/icons/ic_about-us-usage-based.svg';

import imgTestimonialPtiInsuranceLogo from '@hive-microsite-ui/assets/imgs/img_testimonial-pti-insurance-logo.svg';
import imgTestimonialJagadiriLogo from '@hive-microsite-ui/assets/imgs/img_testimonial-jagadiri-logo.svg';

import imgMarketConsultExperts from '@hive-microsite-ui/assets/imgs/img_market-consult-our-experts.svg';
import imgMarketBusinessBenefits from '@hive-microsite-ui/assets/imgs/img_market-business-benefits.svg';
import imgMarketProductLaunch from '@hive-microsite-ui/assets/imgs/img_market-product-launch.svg';

import imgAwardAsiaPacific from '@hive-microsite-ui/assets/imgs/img_award-apac-pacific.svg';
import imgAwardSgGoodDesign from '@hive-microsite-ui/assets/imgs/img_award-sg-good-design.svg';
import imgAwardSBRTEA from '@hive-microsite-ui/assets/imgs/img_award-sbr-tea.svg';

export const NavigationConstants = Object.freeze([
  {
    label: `Why HIVE`,
    title: `Why HIVE`,
    goToSection: `section__why-hive`,
    href: null,
  },
  {
    label: `Our Solutions`,
    title: `Our Solutions`,
    goToSection: `section__our-solutions`,
    href: null,
  },
  {
    label: `Testimonials`,
    title: `Testimonials`,
    goToSection: `section__testiminials`,
    href: null,
  },
  {
    label: `Awards`,
    title: `Awards`,
    goToSection: `section__awards`,
    href: null,
  },
  {
    label: `Omnichannel`,
    title: `Omnichannel`,
    goToSection: null,
    href: `https://hive.income.com.sg/omnichannel`,
  },
  {
    label: `Contact Us`,
    title: `Contact Us`,
    goToSection: `section__contact-us`,
    href: null,
  },
] as const);

export const WhyHiveStaticData = [
  {
    icon: icAboutUsForward,
    title: `Swift Product Launch`,
    description: `Accelerate digital transformation with HIVE’s plug and play technology so your organisation can deliver new insurance products in as little as 3 months.`,
    justifySelf: 'start',
  },
  {
    icon: icAboutUsRunning,
    title: `Acquire New Customers`,
    description: `Reach new customers through digital channels, growing your audience with ease.`,
    justifySelf: 'center',
  },
  {
    icon: icAboutUsRoute,
    title: `Access new revenue streams`,
    description: `Add relevant insurance products to complement your existing offerings so you can tap into new revenue streams. Increase your customer’s life-time value through continuous engagement.`,
    justifySelf: 'end',
  },
];

export const InnovateInsuranceStaticData = [
  {
    icon: icAboutUsStackable,
    title: `Stackable`,
    description: `Micro-insurance embedded with lifestyle activity triggers`,
    justifySelf: 'start',
  },
  {
    icon: icAboutUsSubscription,
    title: `Subscription`,
    description: `Insurance based on recurring payments in monthly/pre-defined cycle`,
    justifySelf: 'center',
  },
  {
    icon: icAboutUsParametric,
    title: `Parametric`,
    description: `Insurance that covers the probability of a pre-defined event happening`,
    justifySelf: 'center',
  },
  {
    icon: icAboutUsUsageBased,
    title: `Usage Base`,
    description: `Pay-per-use insurance products measured against time, difference, charging data`,
    justifySelf: 'end',
  },
];

export const ListOfTestimonials = [
  {
    avatar: imgTestimonialPtiInsuranceLogo,
    title: `PTI Insurance`,
    country: `Vietnam`,
    author: `Ms Nguyễn Thị Vân Anh`,
    designation: `Communications Manager at PTI`,
    comments: `“With HIVE, PTI launched innovative stackable products that are first of its kind in Vietnam. PTI was able to serve new customer segments in the insurance market and we also became the first non-life insurer to successfully use technology to develop and launch innovative insurance products for low-income community like gig workers.”`,
  },
  {
    avatar: imgTestimonialJagadiriLogo,
    title: `Jagadiri`,
    country: `Indonesia`,
    author: `Yosia Nugroho`,
    designation: `Head of Product of Jagadiri`,
    comments: `“By reaching new customers, growing faster, and offering innovative products, we are strengthening our position as a digital company. We understand the importance of having insurance coverage that protects our customers against the rising costs of online transportation, hence we’ve launched innovative and practical solutions for them.”`,
  },
];

export const GotoMarketData = [
  {
    image: imgMarketConsultExperts,
    title: `Consult Our Experts To Build New Use Cases`,
    description: `We help you to decide on the best product proposition and support external ecosystem conversations for success.`,
  },
  {
    image: imgMarketBusinessBenefits,
    title: `Deep Dive Into Business Benefits`,
    description: `We help to build business cases and projections so you get approvals easily and quickly.`,
  },
  {
    image: imgMarketProductLaunch,
    title: `Launch Product`,
    description: `We are with you every step of the way to develop a Minimum Viable Product (MVP) that validates HIVE’s technology and prove business value.`,
  },
];

export const ListOfAwards = [
  {
    image: imgAwardAsiaPacific,
    title: `Innovation In Business-To-Business Servicess`,
    description: `APAC Stevie Award recognises HIVE by Income's innovative product design and social impact of JupViec Care.`,
  },
  {
    image: imgAwardSgGoodDesign,
    title: `Category: Sustainable Design`,
    description: `Singapore Good Design Award celebrates HIVE bu Income's dedication to sustainable design and innovative solutions exemplified by JupViec Care.`,
  },
  {
    image: imgAwardSBRTEA,
    title: `Fintech - Financial Technology`,
    description: `SBR Technology Excellence Award recognises HIVE by Income's exceptional effort and leadership in riding the digital disruption wave with the launch of JupViec Care.`,
  },
];

export const EmbedInsurancesData = [
  {
    title: 'Financial Services',
    description: 'Expand your financial offerings with flexible subscription insurance',
  },
  {
    title: 'Travel/Commute',
    description: 'Provide coverage for a rapidly changing risk environment',
  },
  {
    title: 'Gig Economy',
    description: 'Protect on-demand workers with on-demand coverage',
  },
  // {
  //   heading: 'Lifestyle',
  //   description: 'Protect on-demand workers with on-demand coverage',
  // },
];

export type WhyHiveStaticDataType = (typeof WhyHiveStaticData)[number];
export type TestimonialType = (typeof ListOfTestimonials)[number];
export type EmbedInsuranceType = (typeof EmbedInsurancesData)[number];
export enum RecaptchaActionEnum {
  SUBMIT_FORM = 'OFFICIAL_SITE_REQ_DEMO',
}
