import React, { PropsWithChildren } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { chakra } from '@chakra-ui/react';

interface ComponentProps extends PropsWithChildren {
  sectionId: string;
}

const AnimatedChakraSection = chakra(motion.section);

const AnimatedSection: React.FC<ComponentProps> = ({ children, sectionId }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <AnimatedChakraSection
      ref={ref}
      as={`section`}
      id={sectionId}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={`0.5s easeIn`}
      mt={10}
      mb={10}
    >
      {children}
    </AnimatedChakraSection>
  );
};

export default AnimatedSection;
