import React from 'react';
import { Divider, Image, Text, useBreakpointValue } from '@chakra-ui/react';

import { FooterMenu } from './FooterMenu';
import { StyledContainer, StyledFlexBox } from '../Atoms';

import brandLogo from '@hive-microsite-ui/assets/icons/ic_brand-logo.svg';

export const Footer: React.FC = () => {
  const isMobileOnly = useBreakpointValue({ base: true, md: false });

  return (
    <StyledFlexBox py={12} gap={8} flexDirection="column" backgroundColor={isMobileOnly ? 'bg.dark' : 'transparent'}>
      <StyledFlexBox
        gap={8}
        px={{
          base: `26px`,
          md: `32px`,
          lg: `32px`,
        }}
      >
        <Image src={brandLogo} />
        {!isMobileOnly && <FooterMenu />}
      </StyledFlexBox>

      {!isMobileOnly && <Divider borderWidth="1px" borderColor="white" />}

      <StyledContainer
        fontSize="12px"
        lineHeight="16px"
        px={{
          base: `26px`,
          md: `32px`,
          lg: `32px`,
        }}
      >
        <Text>
          HIVE by Income is a Singapore based Insurance-as-a-Service (IaaS) platform, enabling any insurer and digital
          platform owner across the region to launch, localise and scale innovative embedded insurance propositions. Our
          pre-built insurtech integrations enhance our partners’ speed to market, and their ability to capture new
          customer segments and engagement with minimal additional cost and a competitive advantage that is data-driven.
        </Text>
        <br />
        <Text>© 2024 INCOME. All rights reserved.</Text>
      </StyledContainer>
    </StyledFlexBox>
  );
};
