import { extendTheme } from '@chakra-ui/react';
import { fonts } from './fonts';
import { colors } from './colors';
import { components } from './components';

export const hiveBlueUiTheme = extendTheme({
  components,
  fonts,
  colors,
  config: {
    initialColorMode: `light`,
    useSystemColorMode: false,
  },
  typography: {
    h1: {
      fontSize: `2rem`,
      fontWeight: `bold`,
      lineHeight: `3rem`,
    },
    h2: {
      color: `red`,
    },
    body: {
      fontSize: `12px`,
      color: `#FFF`,
    },
  },
  styles: {
    global: {
      body: {
        bg: '#02232E',
        color: '#FFFFFF',
      },
    },
  },
  textStyles: {
    banner: {
      heading: {
        fontWeight: 400,
        fontSize: {
          base: `32px`,
          md: `68px`,
          lg: `96px`,
        },
        letterSpacing: {
          base: `8px`,
          md: `16px`,
          lg: `20px`,
        },
      },
      description: {
        fontSize: { base: '16px', md: '24px' },
        fontWeight: 400,
        lineHeight: { base: '20px', md: '32px' },
        textAlign: 'center',
      },
    },
    section: {
      heading: {
        fontWeight: 700,
        textAlign: `center`,
        fontSize: {
          base: `28px`,
          md: `36px`,
          lg: `48px`,
        },
        lineHeight: {
          base: `32px`,
          md: `48px`,
          lg: `56px`,
        },
      },
      subHeading: {
        fontWeight: 400,
        textAlign: 'center',
        fontSize: {
          base: '16px',
          md: '16px',
          lg: '18px',
        },
        lineHeight: {
          base: '20px',
          md: '20px',
          lg: '32px',
        },
      },
      title: {
        fontWeight: 700,
        fontSize: {
          base: `22px`,
          md: `22px`,
          lg: `34px`,
        },
        lineHeight: {
          base: `32px`,
          md: `32px`,
          lg: `40px`,
        },
        textAlign: {
          base: 'center',
          md: 'left',
        },
      },
      description: {
        fontWeight: 400,
        fontSize: {
          base: `16px`,
          md: `16px`,
          lg: `18px`,
        },
        lineHeight: {
          base: `20px`,
          md: `20px`,
          lg: `22px`,
        },
        textAlign: {
          base: 'center',
          md: 'left',
        },
      },
    },
    card: {
      heading: {
        textAlign: { base: 'center', md: 'left' },
        fontSize: { base: '18px', md: '22px' },
        lineHeight: { base: '24px', md: '32px' },
        fontWeight: 700,
      },
      description: {
        textAlign: { base: 'center', md: 'left' },
        fontSize: { base: '16px', md: '18px' },
        lineHeight: { base: '20px', md: '22px' },
        fontWeight: 400,
      },
    },
  },
});
