import styled from '@emotion/styled';
import { StyledFlexBox } from '../Atoms';

export const StyledHeaderContainer = styled(StyledFlexBox)`
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px !important;
  background: #02232e;
  flex: 1;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 9999;
`;

export const StyledNavBarContainer = styled(StyledFlexBox)`
  flex: 1;
  justify-content: flex-end;
`;
