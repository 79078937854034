import React from 'react';
import { GridItem, useBreakpointValue } from '@chakra-ui/react';
import { AboutHiveCard } from '../Cards';

import { WhyHiveStaticDataType } from '@hive-microsite-ui/constants';
import { ResponsiveGrid } from './ResponsiveGrid';
import { Carousel } from '../Carousel';

interface ComponentProps<TSectionData> {
  sectionData: TSectionData;
}

export const ResponsiveCardWithCarousel: React.FC<ComponentProps<WhyHiveStaticDataType[]>> = ({ sectionData }) => {
  const isMobileOnly = useBreakpointValue({ base: true, md: false, lg: false });

  const renderCardItems = () => {
    return sectionData.map((item, index: number) => {
      return (
        <GridItem key={index} justifySelf={item.justifySelf} maxW={328} minH={240}>
          <AboutHiveCard {...item} />
        </GridItem>
      );
    });
  };

  console.debug(`Use Breakpoints current view is isMobileOnly ${isMobileOnly}`);

  return (
    <ResponsiveGrid noOfItems={sectionData.length}>
      {isMobileOnly ? <Carousel renderCarouselItems={renderCardItems} /> : renderCardItems()}
    </ResponsiveGrid>
  );
};
