import React from 'react';
import { Image, Text } from '@chakra-ui/react';
import { StyledFlexBox } from '../Atoms';
import { SubTitle } from '../Atoms/Typography';

interface ComponentProps {
  icon: string;
  title: string;
  description: string;
}

export const AboutHiveCard: React.FC<ComponentProps> = ({ icon, title, description }) => {
  return (
    <StyledFlexBox
      flexDirection={`column`}
      alignItems={{
        base: `center`,
        md: `flex-start`,
        lg: `flex-start`,
      }}
      gap={6}
    >
      <Image
        w={{
          base: `84px`,
          md: `96px`,
          lg: `104px`,
        }}
        objectFit={`contain`}
        src={icon}
        alt={title}
      />
      <SubTitle>{title}</SubTitle>
      <Text as={`p`} textStyle={`section.description`}>
        {description}
      </Text>
    </StyledFlexBox>
  );
};
