import React, { useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { hiveBlueUiTheme } from '@hive-microsite-ui/styles';

import { ResponsivePageLayout } from './ResponsivePageLayout';

import { httpClient } from '@hive-microsite-ui/services';

export const RootLayout: React.FC = () => {
  const [recaptchaKey, setRecaptchaKey] = useState<string>('');

  const fetchRecaptchaKey = useCallback(async () => {
    try {
      const key = await httpClient.fetchGoogleRecaptchaKey();
      setRecaptchaKey(key ?? ``);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchRecaptchaKey();
  }, [fetchRecaptchaKey]);

  return (
    <ChakraProvider theme={hiveBlueUiTheme}>
      <GoogleReCaptchaProvider key={recaptchaKey} reCaptchaKey={recaptchaKey} scriptProps={{ async: true }}>
        <ResponsivePageLayout>
          <Outlet />
        </ResponsivePageLayout>
      </GoogleReCaptchaProvider>
    </ChakraProvider>
  );
};
