import { Text, TextProps } from '@chakra-ui/react';

type HiveTextProps = {
  TextProps?: TextProps;
  text: string;
};

export const HiveText: React.FC<HiveTextProps> = (props) => {
  const { TextProps, text } = props;
  return (
    <Text py="0" {...TextProps}>
      {text}
    </Text>
  );
};
