import React from 'react';
import { useBreakpointValue } from '@chakra-ui/react';

import { StyledFlexBox } from '../Atoms';
import { HeaderNavigationMenu, MobileDrawerNavigationMenu } from './NavBar';

export const NavigationMenu: React.FC = () => {
  const isMobileOnly = useBreakpointValue({ base: true, md: false, lg: false });

  return <StyledFlexBox>{isMobileOnly ? <MobileDrawerNavigationMenu /> : <HeaderNavigationMenu />}</StyledFlexBox>;
};
