import { Text } from '@chakra-ui/react';
import { Link } from 'react-scroll';

interface Props {
  label: string;
  title: string;
  goToSectionId?: null | string;
  href?: null | string;
  onCloseDrawer?: () => void;
}

export const NavigationLink: React.FC<Props> = ({ label, title, goToSectionId, href, onCloseDrawer }) => {
  return (
    <Link
      to={`${goToSectionId ?? href}`}
      title={title}
      spy={!!goToSectionId}
      smooth={true}
      offset={-140}
      duration={1000}
      onClick={() => {
        if (href) {
          window.location.href = href;
        }
        onCloseDrawer?.();
      }}
    >
      <Text
        as={`span`}
        fontSize={{
          base: `22px`,
          md: `14px`,
        }}
        fontWeight={{
          base: `700`,
          md: `700`,
        }}
        color={{
          base: `white`,
          md: `custom.primary`,
        }}
        cursor={'pointer'}
      >
        {label}
      </Text>
    </Link>
  );
};
