import axios, { AxiosInstance } from 'axios';

export type HiveApiResponse<TResponseData> = {
  respCode: string;
  respData?: TResponseData;
};

export type SubmitBusinessInquiryFormDataPayload = {
  name: string;
  email: string;
  mobileNo: string;
  content: string;
};

export type SubmitBusinessInquiryFormResponseData = {
  message?: string;
  success: boolean;
};

export const httpClient = (() => {
  let axiosInstance: AxiosInstance;

  const __createInstance = () => {
    axiosInstance = axios.create({
      baseURL: import.meta.env.VITE_HIVE_BACKEND_URL,
      timeout: 6 * 10 * 1000, // 60 seconds / 60_000 milliseconds
    });
  };
  return {
    init() {
      __createInstance();
    },
    async fetchGoogleRecaptchaKey() {
      try {
        if (!axiosInstance) {
          console.warn(`Axios instance is not created! Calling httpClient.init to create axios instance`);
          __createInstance();
        }
        const response = await axiosInstance.get<HiveApiResponse<string>>(`/recaptchakey`);
        return response?.data?.respData;
      } catch (error) {
        console.error(`Unable to fetch Google recaptcha key | Reason : ${(error as Error).message}`, error);
        throw error;
      }
    },
    async submitBusinessInquiryFormData(payload: SubmitBusinessInquiryFormDataPayload, recaptchaToken: string) {
      try {
        if (!axiosInstance) {
          console.warn(`Axios instance is not created! Calling httpClient.init to create axios instance`);
          __createInstance();
        }

        const customHeaders = {
          [`Recaptcha-Token`]: recaptchaToken,
        };
        // set custom header Recaptcha-Token
        const response = await axiosInstance.post<HiveApiResponse<SubmitBusinessInquiryFormResponseData>>(
          `/contact`,
          payload,
          {
            headers: customHeaders,
          },
        );
        return response?.data?.respData;
      } catch (error) {
        console.error(`getRecaptchaKey error`, error);
        throw error;
      }
    },
  };
})();
