import { formAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const activeLabelStyles = {
  transform: 'scale(0.85) translateY(-24px)',
};

const floating = definePartsStyle({
  container: {
    _focusWithin: {
      label: {
        ...activeLabelStyles,
      },
    },
    input: {
      border: 'none',
      borderRadius: 0,
      borderBottom: `1px solid white`,
      px: 0,
      _focusVisible: {
        boxShadow: 'none',
        borderBottomColor: `var(--chakra-colors-custom-primary)`,
      },
      _invalid: {
        borderColor: 'var(--hive-colors-red-500)',
        boxShadow: 'none',
      },
      _readOnly: {
        border: 'none',
        cursor: 'default',
      },
    },
    /* select no longer auto transform its label, require manual work */
    'input:not(:placeholder-shown) + label, textarea:not(:placeholder-shown) ~ label': {
      ...activeLabelStyles,
    },
    label: {
      top: 0,
      left: 0,
      zIndex: 2,
      position: 'absolute',
      backgroundColor: 'transparent',
      pointerEvents: 'none',
      mx: 0,
      my: 2,
      transformOrigin: 'left top',
    },
  },
});

export const Form = defineMultiStyleConfig({
  variants: { floating },
});
