import { FormStatus, FormStatusEnum } from './validation-form';

export enum ActionType {
  CHANGE_INPUT_FIELD = 'CHANGE_INPUT_FIELD',
  CHANGE_FORM_STATUS = 'CHANGE_FORM_STATUS',
  SUBMIT_FORM = 'SUBMIT_FORM',
  RESET_FORM = 'RESET_FORM',
  SET_FORM_ERRORS = 'SET_FORM_ERRORS',
}

// Define action types
export type FormAction =
  | { type: ActionType.CHANGE_INPUT_FIELD; fieldName: string; fieldValue: string }
  | { type: ActionType.SET_FORM_ERRORS; payload: { [key: string]: string } }
  | { type: ActionType.CHANGE_FORM_STATUS; status: FormStatus }
  | { type: ActionType.SUBMIT_FORM }
  | { type: ActionType.RESET_FORM };

// Define action creators
export const changeInput = (fieldName: string, fieldValue: string): FormAction => ({
  type: ActionType.CHANGE_INPUT_FIELD,
  fieldName,
  fieldValue,
});

export const changeFormStatus = (status: FormStatus = FormStatusEnum.PENDING): FormAction => ({
  type: ActionType.CHANGE_FORM_STATUS,
  status,
});

export const submitForm = (): FormAction => ({
  type: ActionType.SUBMIT_FORM,
});

export const resetForm = (): FormAction => ({
  type: ActionType.RESET_FORM,
});

export const setFormErrors = (payload: { [key: string]: string }): FormAction => ({
  type: ActionType.SET_FORM_ERRORS,
  payload,
});
