import React, { PropsWithChildren } from 'react';
import { Text } from '@chakra-ui/react';

export const SubTitle: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Text as={`h5`} textStyle={`section.title`}>
      {children}
    </Text>
  );
};
