import { useBreakpointValue } from '@chakra-ui/react';
import { EmbedInsuranceMobile } from './EmbedInsuranceMobile';
import { EmbedInsuranceDesktop } from './EmbedInsuranceDesktop';
import { EmbedInsurancesData } from '@hive-microsite-ui/constants';

export const EmbedInsurance: React.FC = () => {
  const isMobileOnly = useBreakpointValue({ base: true, md: false });

  if (isMobileOnly) {
    return <EmbedInsuranceMobile embedInsurances={EmbedInsurancesData} />;
  }

  return <EmbedInsuranceDesktop embedInsurances={EmbedInsurancesData} height={150} />;
};

export default EmbedInsurance;
