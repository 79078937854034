import React from 'react';
import { Text, TextProps } from '@chakra-ui/react';

export const Heading: React.FC<TextProps> = ({ children, ...props }) => {
  return (
    <Text as="h2" textStyle={`section.heading`} {...props}>
      {children}
    </Text>
  );
};
