import React from 'react';
import { Text, TextProps } from '@chakra-ui/react';

export const SubHeading: React.FC<TextProps> = ({ children, ...props }) => {
  return (
    <Text
      as="p"
      maxW={{
        base: `380px`,
        md: `400px`,
        lg: `500px`,
      }}
      textStyle={`section.subHeading`}
      {...props}
    >
      {children}
    </Text>
  );
};
