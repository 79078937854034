import React, { useState } from 'react';
import { EmbedInsuranceItem } from './EmbedInsuranceItem';

import { Heading, SubHeading } from '@hive-microsite-ui/components';
import { StyledContainer, StyledFlexBox } from '@hive-microsite-ui/components/Atoms';
import { EmbededInsuranceList } from './EmbededInsuranceList';

import { EmbedInsuranceType } from '@hive-microsite-ui/constants';

export const EmbedInsuranceDesktop: React.FC<{
  embedInsurances: EmbedInsuranceType[];
  height: number;
}> = ({ embedInsurances, height = 160 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [y, setY] = useState(0);

  const handleOpacity = (index: number) => {
    const differences = currentIndex - index;
    return Math.abs(differences) >= 2 ? 0 : currentIndex === index ? 1 : 0.24;
  };

  const handleHightlightEmbed = (index: number) => {
    setY((prev) => prev + (currentIndex - index) * height);
    setCurrentIndex(index);
  };

  return (
    <StyledFlexBox mt="36px" gap={4}>
      <StyledFlexBox flexDirection="column" gap={8} flex={1}>
        <Heading textAlign={{ base: 'center', md: 'left', lg: 'left' }}>Embed Insurance.</Heading>
        <SubHeading textAlign={{ base: 'center', md: 'left', lg: 'left' }}>
          HIVE enables companies from any sector to embed insurance into core products and services.
        </SubHeading>
        <EmbededInsuranceList
          data={embedInsurances}
          currentIndex={currentIndex}
          onClick={(index) => {
            handleHightlightEmbed(index);
          }}
        />
      </StyledFlexBox>

      <StyledContainer
        position="relative"
        mt="86px"
        maxW={'624px'}
        maxH="160px"
        px="40px"
        _before={{
          content: '""',
          position: 'absolute',
          inset: 0,
          borderRadius: '64px',
          padding: '8px',
          background: 'linear-gradient(180deg, #FFE68B 0%, #FA669C 26.95%, #84ACF3 59.24%, #A6F3FF 100%)',
          WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
          WebkitMaskComposite: 'xor',
          maskComposite: 'exclude',
        }}
        flex={0.75}
      >
        <StyledFlexBox flexDirection="column">
          {(embedInsurances ?? []).map((item, index) => (
            <EmbedInsuranceItem
              height={`${height}px`}
              key={index}
              isActive={currentIndex === index}
              heading={item.title}
              text={item.description}
              positionY={y}
              opacity={handleOpacity(index)}
            />
          ))}
        </StyledFlexBox>
      </StyledContainer>
    </StyledFlexBox>
  );
};
