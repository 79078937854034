import React from 'react';
import { Image } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { StyledFlexBox } from '../Atoms';

import imgBallBlue from '@hive-microsite-ui/assets/imgs/img_ball-blue.svg';
import imgBallPink from '@hive-microsite-ui/assets/imgs/img_ball-pink.svg';

export interface PageSectionDividerProps {
  direction?: 'left' | 'right';
  dotIndicatorType?: `blue` | `pink`;
  maxWidth: string;
  inView: boolean;
}

const AnimatedFlexBox = motion(StyledFlexBox);

export const AnimatedDivider: React.FC<PageSectionDividerProps> = ({
  inView,
  dotIndicatorType = `blue`,
  maxWidth = `40%`,
  direction = 'left',
}) => {
  const imageSrc = dotIndicatorType === `blue` ? imgBallBlue : imgBallPink;
  return (
    <AnimatedFlexBox
      pos={`absolute`}
      w={`full`}
      zIndex={999}
      flexDirection={direction === `left` ? 'row' : 'row-reverse'}
    >
      <AnimatedFlexBox
        h={`5px`}
        bg={`#FFFFFF`}
        as={motion.div}
        initial={{ width: '0%', opacity: 0 }}
        animate={inView ? { width: maxWidth, opacity: 1 } : { width: 0, opacity: 0 }}
        transition={{
          duration: 0.5,
          ease: `linear`,
        }}
      />
      <Image
        src={imageSrc}
        w={{
          base: 5,
          md: dotIndicatorType === `pink` ? 10 : 7,
          lg: dotIndicatorType === `pink` ? 12 : 7,
        }}
        objectFit={`contain`}
        zIndex={99}
        mt={{
          base: `-8px`,
          md: dotIndicatorType === `pink` ? `-18px` : `-12px`,
          lg: dotIndicatorType === `pink` ? `-22px` : `-12px`,
        }}
        ml={direction === `left` ? '-2px' : '0px'}
        mr={direction === `left` ? '0px' : '-4px'}
      />
    </AnimatedFlexBox>
  );
};
